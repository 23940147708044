<template>
  <div class="pt-10">
    <vx-card v-if="childrenDetails" class="mb-base">
      <div class="vx-breadcrumb md:block hidden">
          <ul class="flex flex-wrap items-center">
            <li class="inline-flex items-center">
                <router-link :to="{ name: 'director-learning-centre-list'}">Manage</router-link>
                <span class="breadcrumb-separator mx-2">
                    <span class="feather-icon select-none relative">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="24px"
                        height="24px"
                        viewBox="0 0 24 24"
                        fill="none"
                        stroke="currentColor"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        class="feather feather-chevrons-right"
                      >
                        <polyline points="13 17 18 12 13 7" />
                      </svg>
                    </span>
                  </span>
            </li>

            <li class="inline-flex items-center">

                 <router-link :to="{ name: 'director-learning-centre-view-list', params:{ type:'children',id: childrenDetails.learningCenterId }}">Children</router-link>
                <span class="breadcrumb-separator mx-2">
                    <span class="feather-icon select-none relative">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="24px"
                        height="24px"
                        viewBox="0 0 24 24"
                        fill="none"
                        stroke="currentColor"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        class="feather feather-chevrons-right"
                      >
                        <polyline points="13 17 18 12 13 7" />
                      </svg>
                    </span>
                  </span>
            </li>
            <li class="inline-flex items-center">
                <a href="javascript:void(0)" class="">{{ childrenDetails.fullName}}</a>
            </li>
            </ul>
        </div>

      <div class="vx-col w-full my-5 mb-base">
            <div class="">
              <div class="flex items-center my-5">
                <h2 class="font-bold" style="color: black">{{ childrenDetails.fullName}}</h2>
                <div class="ml-auto">
                  <vs-button class="mr-4" @click.stop="editTeacher( childrenDetails._id)">Edit</vs-button>
                  <vs-button color="danger" @click.stop="deleteTeacher( childrenDetails._id)">Delete</vs-button>
                </div>

              </div>

              <vs-row vs-align="flex-start" vs-type="flex" vs-justify="left" vs-w="12">

                <vs-col vs-type="flex" vs-justify="left" vs-align="left" vs-w="12">
                 <div class="w-full mb-5">
                  <div class="img-container w-64">
                   <img v-bind:src="childrenDetails.photo"  alt="img" class="responsive" v-if="childrenDetails.photo && childrenDetails.photo != 'test.jop' ">
                <img v-bind:src="$defaultImage"  alt="img" class="responsive" v-else>
                  </div>
                  </div>
                </vs-col>

                 <vs-col vs-type="flex" vs-justify="left" vs-align="left" vs-w="6">
                  <div class="w-full mb-5">
                    <label class="font-semibold text-dark">Age Group:</label>
                    <p class="text-dark" v-if="childrenDetails.ageDetails && childrenDetails.ageDetails.length > 0">{{ childrenDetails.ageDetails.length > 0 ? childrenDetails.ageDetails[0].ageGroup + ' years': '' }}</p>
                  </div>
                </vs-col>

                <vs-col vs-type="flex" vs-justify="left" vs-align="left" vs-w="6">
                  <div class="w-full mb-5">
                    <label class="font-semibold text-dark">Room:</label>

                    <p class="text-dark" v-if="childrenDetails.roomDetails && childrenDetails.roomDetails.length > 0">{{ "undefined" != typeof childrenDetails.roomDetails && childrenDetails.roomDetails.length > 0 ?  childrenDetails.roomDetails[0].name:'' }}</p>
                  </div>
                </vs-col>


                <vs-col vs-type="flex" vs-justify="left" vs-align="left" vs-w="6">
                  <div class="w-full mb-5">
                    <label class="font-semibold text-dark">Parent/Guardians:</label>
                    <p class="text-dark">
                    <ul v-if="parentDetails">
                       <li  class="flex flex-wrap items-center justify-between mb-5 child-parent-list" v-for="(parent,index) in parentDetails" v-bind:key="index" >

                         <div  class="wrap-names">
                            <router-link :to="{ name: 'director-parent-edit', params:{ child: childrenDetails._id ,id: parent._id }}">{{parent.fullName}}</router-link>
                            <span v-if="parent.isPrimary"> ( Primary Parent )</span>
                         </div>
                         <a href="#" class="vs-component vs-button mr-4 vs-button-primary vs-button-filled" @click="resetPassword(parent._id)">Reset Password</a>
                       </li>
                    </ul>
                    </p>
                  </div>
                </vs-col>
                <vs-col class="flex w-full md:w-1/2">
                  <div class="w-full mb-5">
                    <label class="font-semibold text-dark">Account Status</label>
                    <p>

                      <vs-button v-if="childrenDetails.accountStatus"
                                 class="mr-4 mt-1"
                                 :color="childrenDetails.accountStatus =='Active' ? 'success' :'danger'"
                      >{{ childrenDetails.accountStatus }}
                      </vs-button>
                    </p>

                  </div>
                </vs-col>



              </vs-row>
            </div>

      </div>

    </vx-card>
  </div>
</template>
<script>
  import { mapActions } from "vuex";
  // import vSelect from "vue-select";
  import _ from 'lodash';
  import Swal from 'sweetalert2/dist/sweetalert2';
  import 'sweetalert2/src/sweetalert2.scss';


  export default {
    data() {
      return {
        childrenDetails: {},
        parentDetails: {},
      }
    },
    watch: {},
    computed: {},
    methods: {
      ...mapActions("center", [
        "getDirectorChildDetails",
        "deleteDirectorChild",


      ]),
       ...mapActions("centerAdmin", [
      "resetUserPassword"
    ]),
     async getDetails(id) {
        this.$vs.loading();
       await this.getDirectorChildDetails(id)
          .then(async (res) => {
             this.childrenDetails = await res.data.data;
            let parents = this.childrenDetails.parents;
            let parentDetails = this.childrenDetails.parentDetails;

            let deletedParentDetails = _.remove( parentDetails, (ele) => {
              return ele.deleted == true;
            });

            let primaryParent = _.find(parents ,(o) => {
                  return o.isPrimary == true;
            })
           _.forEach( parentDetails, (o,k)  => {
                if( "undefined" != primaryParent && o._id == primaryParent.parentId ){
                   parentDetails[k].isPrimary = true ;
                }else {
                  parentDetails[k].isPrimary = false;
                }
            });
            this.parentDetails = parentDetails;
            this.$vs.loading.close()

          }).catch(err => {
            this.$vs.loading.close()
            //console.error(err)
          })
      },
      showMessage(title, message, color) {
        this.$vs.notify({
          title: title,
          text: message,
          iconPack: 'feather',
          icon: 'icon-alert-circle',
          color: color
        })
      },
      editTeacher(id){
        this.$router.push({name: 'director-children-edit', params: { id: id } });
      },
      deleteTeacher(id){
        Swal.fire({
          title: 'Are you sure you want to delete?',
          text: "You won't be able to revert this!",
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Yes, delete it!'
        }).then((result) => {
          if (result.value) {
            this.acceptDeleteAlert(id)
          }
        })
      },
      acceptDeleteAlert(id) {
        this.$vs.loading()
        this.deleteDirectorChild(id)
          .then((res) => {
            if (res.status === 200) {
              this.$vs.loading.close()
              this.showMessage("Success", "Child deleted successfully.", "success");
            }
            this.$router.push({name: "director-children-list"}).catch(() => {});
          })
          .catch(err => {
            this.$vs.loading.close()
          })
      },

       resetPassword(id) {
        Swal.fire({
          title: "Are you sure?",
          text: "Are you sure you want reset password for this user?",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Yes!",
        }).then((result) => {
        if (result.value) {
          this.acceptPasswordResetAlert(id);
        }
      });
    },

     acceptPasswordResetAlert(id) {
      this.resetUserPassword(id)
        .then((res) => {
          this.showChangePasswordSuccess();
        })
        .catch((err) => {
          console.error(err);
        });
    },
    showChangePasswordSuccess() {
      this.$vs.notify({
        title: "Success",
        text: "Reset password mail sent to user.",
        iconPack: "feather",
        icon: "icon-alert-circle",
        color: "success",
      });
    },

    },

    created() {
      let id = this.$route.params.id;

      this.getDetails(id);
    }
  }
</script>
